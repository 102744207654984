import '@fontsource/roboto-condensed/latin-300.css' 
import '@fontsource/roboto-condensed/cyrillic-300.css'
import '@fontsource/roboto-condensed/latin-400.css'
import '@fontsource/roboto-condensed/cyrillic-400.css'
import '@fontsource/roboto-condensed/cyrillic-400-italic.css'
import '@fontsource/roboto-condensed/latin-700.css'
import '@fontsource/roboto-condensed/cyrillic-700.css'
import '@fontsource/roboto-condensed/cyrillic-700-italic.css'

import '@fontsource/playfair-display/cyrillic-400.css'
import '@fontsource/playfair-display/cyrillic-600.css'
import '@fontsource/playfair-display/cyrillic-700.css'
// import '@fontsource/roboto/cyrillic-600.css'

import 'antd/dist/antd.css'
import './src/assets/style/reset.scss'
import './src/assets/style/variables-css.scss'

import './src/assets/style/common.scss'
import './src/assets/style/global.scss'

export const onServiceWorkerUpdateReady = () => {
    window.location.reload(true)
}
